<template>

  <div v-if="!redirect">

    <div v-if="course">

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div class="knowledge-base-jumbotron-content vx-card"
               :style="`background-image: url(https://app-cdn.rapidseminars.com/thumbnails/${course.thumbnail_id || ''}.jpeg); background-position: top`">
            <div class="knowledge-base-jumbotron-content rounded-lg p-8 lg:p-12" style="backdrop-filter: blur(25px)">
              <div class="flex justify-between items-center">
                <div class="flex flex-wrap content-center pl-6 pr-6">
                  <h1 class="mb-2 text-white w-full" style="font-size: 4rem">{{ course.name }}</h1>

                </div>
                <img class="rounded ml-10 hidden lg:block"  :src="`https://app-cdn.rapidseminars.com/thumbnails/${course.thumbnail_id || ''}.jpeg`" v-if="course.thumbnail_id" width="300px">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row match-height hidden lg:flex">
        <div class="vx-col w-1/3">
          <h3 class="font-bold mb-3 ml-1">Course Progress</h3>
        </div>
        <div class="vx-col w-1/3">
          <h3 class="font-bold mb-3 ml-1">Current Lesson</h3>
        </div>
        <div class="vx-col w-1/3">
          <h3 class="font-bold mb-3 ml-1">Resume Course</h3>
        </div>
      </div>

      <div class="vx-row match-height">
        <div class="vx-col w-full lg:w-1/3 mb-6 ">
          <vx-card>
            <h3 class="font-bold mb-4 block lg:hidden">Course Progress</h3>
            <vs-progress
              :percent="currentProgress" class="block shadow-md self-center w-full mb-3"
              color="primary" height="11"></vs-progress>
            <div class="flex items-center justify-between">
              <h5 class="mr-2">0%</h5>
              <h5 class="ml-2">100%</h5>
            </div>
          </vx-card>

        </div>
        <div class="vx-col w-full lg:w-1/3 mb-6 ">
          <vx-card>
            <h3 class="font-bold mb-4 block lg:hidden">Current Lesson</h3>
            <p>{{lesson_current.lesson}}</p>
          </vx-card>
        </div>
        <div class="vx-col w-full lg:w-1/3 mb-6 ">
          <vx-card>
            <h3 class="font-bold mb-4 block lg:hidden">Resume Course</h3>
            <vs-button class="w-full" color="primary" icon="icon-play" icon-pack="feather"
                       @click="$router.push(`/courses/${course.id}/${lesson_current.module_id}/${lesson_current.lesson_id}`)">Resume Lesson</vs-button>
          </vx-card>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div v-for="module in course.modules">
            <div class="flex justify-between mb-1 p-2">
              <h4 class="font-light">{{ module.name }}</h4>
              <h4 class="font-bold">{{ module.lessons.length }} Lessons</h4>
            </div>
            <div class="mb-6 p-6 rounded-lg" style="background-color: rgba(0,0,0,0.15) ">
              <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 ">
                <div v-for="(lesson, index) in module.lessons" class="flex items-center justify-center">
                  <vx-card class="flex items-center w-full" style="min-height: 15rem">
                    <div class="grid grid-cols-2 gap-5 mb-4 w-full" v-if="lesson.video_thumbnail_id">
                      <div class="flex items-center  ">
                        <img :src="`https://app-cdn.rapidseminars.com/thumbnails/${lesson.video_thumbnail_id}.jpeg`"
                             class="rounded w-full">
                      </div>
                      <div class="flex items-center justify-end">
                        <h4 class="font-bold">{{ lesson.name }}</h4>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 gap-5 mb-4 w-full" v-else>
                      <div class="flex items-center justify-end">
                        <h4 class="font-bold">{{ lesson.name }}</h4>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 w-full"  v-if="lesson.id !== lesson_current.lesson_id">
                      <vs-button v-if="progress.modules[module.id][lesson.id]" class="w-full font-bold"
                                 color="warning"
                                 icon="icon-refresh-cw" icon-pack="feather" type="gradient" @click="$router.push(`/courses/${course.id}/${module.id}/${lesson.id}`)">
                        Repeat Lesson
                      </vs-button>
                      <vs-button v-if="!progress.modules[module.id][lesson.id]" class="w-full font-bold" color="primary"
                                 icon="icon-play"
                                 icon-pack="feather" type="gradient" @click="$router.push(`/courses/${course.id}/${module.id}/${lesson.id}`)">Start Lesson
                      </vs-button>
                    </div>
                    <div class="grid grid-cols-1" v-if="lesson.id === lesson_current.lesson_id">
                      <vs-button  class="w-full font-bold" color="success" icon="icon-play" icon-pack="feather"
                                  type="gradient" @click="$router.push(`/courses/${course.id}/${module.id}/${lesson.id}`)">Resume Lesson
                      </vs-button>
                    </div>
                  </vx-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row match-height">
        <div class="vx-col w-full mb-3">
          <h3 class="font-bold ml-1">Lesson Actions</h3>
        </div>

        <div class="vx-col w-full lg:w-4/12 mb-4">
          <vx-card class="">

            Use the button below to reset your progress, this cannot be reversed.
            <vs-button :disabled="!this.progress.modules || this.currentProgress === 0"
                       class="w-full mt-3"
                       color="danger"
                       icon-pack="feather"
                       icon="icon-refresh-cw"
                       @click="restartCourse()">
              Restart Course Progress
            </vs-button>

          </vx-card>
        </div>

        <div class="vx-col w-full lg:w-4/12 mb-4">
          <vx-card class="">
            Complete at least <span class="text-weight-90">100%</span> of this course to request a Record
            of Completion.

            <vs-button :disabled="currentProgress < 100" class="w-full mt-3" color="primary" icon="icon-archive" icon-pack="feather">Record of Completion</vs-button>

          </vx-card>
        </div>
      </div>

    </div>

  </div>

</template>

<style lang="scss">

.thumbnail {
  width: 15rem;
}

</style>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      course: null,

      progress: {},
      chartOptions: {
        chart: {
          height: 280,
          type: 'radialBar',
        },

        series: [this.currentProgress],

        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: '70%',
            },

            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '13px',
              },
              value: {
                color: '#111',
                fontSize: '30px',
                show: true,
              },
            },
          },
        },
        colors: ['#00B5FF'],
        stroke: {lineCap: 'round'},
        labels: ['Completion'],
      },
      currentProgress: 0,
      lessons: [],
      lesson_current: {},
      lesson_future: {},
      redirect: false
    }
  },
  methods: {
    continueCourse(lessonId = undefined, moduleId = undefined) {

      if (this.currentProgress >= 100) {
        this.redirect = false
        return this.$vs.notify({
          title: 'Course Is Complete',
          text: 'The course has been completed, there is no more videos to resume',
          color: 'success',
          position: 'top-right'
        });
      }

      if (!lessonId || !moduleId) {

        let moduleIds = Object.keys(this.progress.modules);

        for (let __moduleId of moduleIds) {

          if (moduleId && lessonId) {
            break;
          }

          let lessonIds = Object.keys(this.progress.modules[__moduleId]);

          for (let __lessonId of lessonIds) {
            if (!this.progress.modules[__moduleId][__lessonId]) {
              lessonId = __lessonId;
              moduleId = __moduleId;
              break;
            }
          }

        }

      }

      if (lessonId && moduleId) {
        // Vue-Router is a bitch for some reason and doesn't update the page on $router.push

        // LMAO Fixed That Mr Wolfe
        this.$router.push(`/courses/${this.course.id}/${moduleId}/${lessonId}`)

      }

    },
    restartCourse() {

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are you sure you want to reset your progress?',
        accept: () => {
          this.$vs.loading();

          this.$http.post(`courses/progress/${this.progress.id}/reset`)
            .then(response => {
              this.$vs.loading.close();
              if (response.data) {

                location.reload();

              }
            })
            .catch(exception => {
              this.$vs.loading.close();
            });

        },
      });

    },
    moduleCompleteCount (id) {
      let complete = 0;
      this.progress.modules[id].forEach(item => {
        if (item === true) complete++
      })
      return complete
    },
    loadProgress(courseId) {
      this.$http.get(`courses/progress/course/${courseId}`)
        .then(response => {

          console.log(response.data.data)


          if (response.data) {

            this.progress = response.data.data;

            if (this.progress.course_progress) {
              this.currentProgress = (this.progress.course_progress * 100).toFixed(0);
            }

            let lastLeftOff = undefined;
            let index = 0;
            let lastWasCurrent = false;


            this.lessons = this.lessons.map(lesson => {
              if (this.progress.modules[lesson.module_id]) {
                if (this.progress.modules[lesson.module_id][lesson.lesson_id]) {
                  index++;
                  lesson.completed = this.progress.modules[lesson.module_id][lesson.lesson_id];
                }
              }

              if (lastWasCurrent) {
                this.lesson_future = lesson;
                lastWasCurrent = false;
              }

              if (lastLeftOff === undefined && !this.progress.modules[lesson.module_id][lesson.lesson_id]) {
                this.lesson_current = lesson;
                lastWasCurrent = true;
                lastLeftOff = index;
              }

              return lesson;
            });

            if (this.$router.currentRoute.query.opt === 'continue') {
              this.continueCourse();
            }

          }

        })
        .catch(exception => {

          if (exception.response) {

            if (exception.response.status === 404) {

              this.$http.post(`courses/progress`, {course_id: this.course.id})
                .then(() => {
                  this.loadProgress(courseId);
                });

            }

          }

        });
    },
  },
  mounted() {
    const courseId = this.$route.params.courseId;

    if (this.$router.currentRoute.query.opt === 'continue') {
      this.redirect = true
    }

    this.$http.get(`courses/${courseId}/rich?thumbnails=true`)
      .then(response => {

        if (response.data) {
          this.course = response.data.data;

          this.course = response.data.data;

          setTimeout(() => {

            this.course.modules.forEach(module => {

              module.lessons.forEach(lesson => {

                this.lessons.push({
                  module: module.name,
                  lesson: lesson.name,

                  progress: 0,
                  duration: lesson.duration,

                  lesson_id: lesson.id,
                  module_id: module.id,
                  thumbnail_id: lesson.video_thumbnail_id,
                });

              });

            });

            this.loadProgress(courseId);
          }, 0);

        }

      });


  },

};
</script>
